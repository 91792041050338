import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {

  constructor(
    private router : Router
  ){
    this.getScreenSize();
  }

  ngOnInit(): void {
    if(this.srcWidth > 475){
      this.isMenuCollapsed = true;
    }
    else{
      this.isMenuCollapsed = false;
    }
  }

  srcHeight:any;
  srcWidth:any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    ////console.log(this.scrHeight, this.scrWidth);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }

  loginPage(){
    // let url = environment.redirectingSiteUrl;
    // window.location.href = `${url}/login`
    window.location.href = environment.redirectingSiteUrl
  }
  signUp(){
    this.router.navigate(['/signup'])
  }
  redirectPricing(){
    this.router.navigate(['/pricing'])
  }
  redirectContactUs(){
    this.router.navigate(['/contact-us'])
  }
  redirectToBType(){
    let url = environment.redirectingSiteUrl;
    window.location.href = `${url}/business-type`
  }
  isMenuCollapsed:boolean=true;
  openMenu(){
    this.isMenuCollapsed = !this.isMenuCollapsed
  }
}
