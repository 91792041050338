<nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="">
        <img src="./../../../assets/images/common/home_page_logo.svg" alt="logo">
        </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"  [attr.aria-expanded]="!isMenuCollapsed" (click)="isMenuCollapsed = !isMenuCollapsed">
        <img src="./../../../assets/images/common/menu.svg" alt="" class="menu-img">
      </button>
      <div *ngIf="isMenuCollapsed">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        </ul>
        <div class="right_container" >
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link pricing" (click)="redirectPricing()">Pricing</a>
              </li>
              <li class="nav-item">
                <a class="nav-link pricing" (click)="redirectContactUs()">Contact Us</a>
              </li>
              <li class="nav-item" *ngIf="srcWidth > 475">
                <div class="vr_line"></div>
              </li>
              <li class="nav-item">
                <a class="nav-link signin" (click)="loginPage()">Sign in</a>
              </li>
                <li class="nav-item">
                    <button class="signup_btn" (click)="redirectToBType()">
                        Generate Website
                    </button>
                </li>
            </ul>
        </div>
      </div>
    </div>
  </nav>
