import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  constructor(
    private router : Router
  ){}

  termsPage : boolean = false;
  privacyPage : boolean = false;
  currentPageUrl : any;
  ngOnInit(){
    this.router.events.subscribe((ev) => {
      if(ev instanceof NavigationEnd){
        // console.log(ev)
        this.currentPageUrl = ev.url;
        if(this.currentPageUrl.includes('terms-conditions')){          
          return;
        }
        if(this.currentPageUrl.includes('privacy-policy')){
          return;
        }
      }
    })
  }
}
